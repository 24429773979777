html, body {
    margin: 0;
    padding: 0;
    max-width: 100vw;
    max-height: 100vh;
    
}

:root:has(.noScroll) {
    overflow: hidden;
}